import Hero from "./../components/hero/Hero";
import Content from "./../components/content/Content";
import Projects from "./../components/projects/Projects";
import Clients from "./../components/clients/Clients";
import Footer from "./../components/footer/Footer";

function HomePage() {
	return (
		<>
			<Hero title="Rafael Peralta Jr" role="Web Developer" />
			<Content title="About Me" copy="I am a web developer from New Jersey. Since beginning my journey as a web developer, I have done freelance work, remote work for agencies and collaborated with talented people to create websites, landing pages and interactive web apps for several Fortune 500 companies. My passion and determination for what I do has allowed me to further improve my skill set and continuously learn web, app and business development." />
			<Projects />
			<Clients title="I'm proud to have collaborated with some amazing companies" />
			<Footer title="Contact Me" />
		</>
	);
}

export default HomePage;