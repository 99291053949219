import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import './Footer.scss';

function Footer({ title }) {
	return (
		<>
			<section className="Footer flex bg-gray-700">
				<div className="Footer__container container max-w-3xl m-auto px-12 md:px-0">
					<div className="Footer__row">
						<div className="Footer__col w-full text-center py-20">
                            <h5 className="Footer__title text-2xl uppercase font-bold text-white tracking-widest mb-6">{ title }</h5>
                            <p className="Footer__copy text-xl text-white font-normal leading-9">
                                Interested in working together? <br className="md:hidden" />
                                <a href="mailto:rperaltajr+portfolio@gmail.com" className="hover:text-blue-500 dotted">Email me</a>
                            </p>
                            <div className="Footer__social my-10 flex space-x-5 justify-center">
                                <a 
                                    href="https://www.linkedin.com/in/rafaelperaltajr" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    title="LinkedIn"
                                    className="text-2xl border rounded-full p-3 bg-blue-500 border-blue-500 hover:bg-white hover:text-blue-500 hover:border-white"
                                >
                                    <FaLinkedinIn />
                                </a>
                                <a 
                                    href="https://github.com/RPeraltaJr" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    title="GitHub" 
                                    className="text-2xl border rounded-full p-3 bg-blue-500 border-blue-500 hover:bg-white hover:text-blue-500 hover:border-white"
                                >
                                    <FaGithub />
                                </a>
                            </div>
                            <div className="flex space-x-4 justify-center opacity-75">
                                <p className="text-md text-white font-light leading-9">&copy; Rafael Peralta Jr.</p>
                                <p className="text-md text-white font-light leading-9">|</p>
                                <p className="text-md text-white font-light leading-9">{ new Date().getFullYear() }</p>
                            </div>
						</div>
					</div>
				</div>
			</section>
		</>
	); 
}

export default Footer;