import './Content.scss';

function Content({ title, copy }) {
    return ( 
        <>
            <section className="Content pt-20 md:pt-32 pb-10">
                <div className="Content__container w-full md:w-1/2 mx-auto px-12 md:px-0">
                    <div className="Content__row">
                        <div className="Content__col text-center">
                            <div className="Content__title">
                                <h3 className="text-2xl uppercase font-bold text-gray-500 tracking-widest pt-8 md:pt-16 pb-8 mb:pb-10">
                                    { title }
                                </h3>
                            </div>
                            <div className="Content__copy my-10 md:my-16 text-gray-500 text-xl md:text-2xl leading-9 md:leading-11 font-normal">
                                <p>{ copy }</p>
                            </div>
                        </div>
                    </div>
                </div>      
            </section>
        </>
    );
}

export default Content;