import sanityClient from "./../../client";
import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import './Projects.scss';

function Projects() {

    const [projectData, setProject] = useState(null);

    // Documentation: https://www.sanity.io/docs/query-cheat-sheet
    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "project"] | order(priority desc, _updatedAt desc) {
                title,
                date,
                image {
                    asset-> {
                        _id,
                        url
                    },
                    alt
                },
                projectType,
                status,
                link,
                repo,
                description
            }`)
            .then((data) => setProject(data))
            .catch(console.error);
    }, []);

    return ( 
        <>
            <section className="Projects relative overflow-hidden" id="projects">
                <div className="Projects__container container mx-auto max-w-6xl pb-16 md:pb-32">
                    <div className="Projects__row px-6 md:px-0">

                        { projectData && projectData.map((project, index) => (
                            <div className="Projects__col flex flex-wrap md:flex-nowrap border border-gray-300 md:border-none mb-10 md:mb-0 md:rounded-none rounded" key={index}>
                                <div className="Projects__image w-full md:w-1/2">
                                    <div 
                                        style={{ backgroundImage: `url(${project.image.asset.url})` }} 
                                        className="bg-no-repeat bg-left-top bg-cover bg-gray-300 h-full w-full" 
                                        role="img" 
                                        aria-label={project.title}></div>
                                </div>
                                <div className="Projects__description w-full md:w-1/2 px-12 md:px-0 bg-white md:bg-none">
                                    <div className="py-12 md:py-24">
                                        <h4 className="Projects__title text-2xl uppercase font-bold text-gray-500 tracking-widest mb-3" data-aos="fade-up" data-aos-duration="700" data-aos-delay="450">
                                            {project.title}
                                        </h4>
                                        <div className="Projects__copy text-xl text-gray-500 font-normal leading-9" data-aos={ index % 2 === 0 ? 'fade-left' : 'fade-right' } data-aos-duration="700">
                                            {/* PortableText renders the proper formatting for block content */}
                                            <PortableText value={project.description} />
                                        </div>
                                        <div className="Projects__buttons mt-7 md:space-x-6">
                                            <a 
                                                href={project.link}
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="inline-block bg-gray-700 text-white rounded py-3 px-10 uppercase tracking-widest font-semibold hover:bg-blue-500 hover:text-white w-full md:w-auto text-center mb-4 md:mb-0">
                                                    Demo
                                            </a>

                                            {/* Conditional Statement for rendering different button */}
                                            {(() => {
                                                if(project.projectType === 'client') {
                                                    return (
                                                        <button className="cursor-not-allowed inline-block bg-white border border-gray-500 text-gray-500 rounded py-3 px-7 uppercase tracking-widest font-semibold hover:bg-gray-100 w-full md:w-auto text-center">
                                                            <FaEyeSlash className="inline mr-1" /> Source Private
                                                        </button>
                                                    )
                                                } else {
                                                    return (
                                                        <a 
                                                            href={project.repo} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer" 
                                                            className="inline-block bg-white border border-gray-500 text-gray-500 rounded py-3 px-7 uppercase tracking-widest font-semibold hover:bg-blue-500 hover:text-white hover:border-blue-500 w-full md:w-auto text-center"
                                                        >
                                                            <FaEye className="inline mr-1" /> View Source
                                                        </a>
                                                    )
                                                }
                                            })()}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                        
                    </div>
                </div>      
            </section>
        </>
    );
}

export default Projects;