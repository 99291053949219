import aramark from "../../assets/images/logos/aramark.png";
import crst from "../../assets/images/logos/crst.svg";
import draftkings from "../../assets/images/logos/draftkings.svg";
import encompass from "../../assets/images/logos/encompass-health.png";
import expedia from "../../assets/images/logos/expedia-group.svg";
import gallo from "../../assets/images/logos/gallo.png";
import hanes from "../../assets/images/logos/hanesbrands.svg";
import jennieO from "../../assets/images/logos/jennie-o.svg";
import lennar from "../../assets/images/logos/lennar.svg";
// import mercy from "../../assets/images/logos/mercy.svg";
import panda from "../../assets/images/logos/panda-express.png";
import paychex from "../../assets/images/logos/paychex.png";
import spirit from "../../assets/images/logos/spirit.png";
import tacobell from "../../assets/images/logos/tacobell.svg";
import uopx from "../../assets/images/logos/uopx.png";
import vista from "../../assets/images/logos/vista.svg";
import wellpath from "../../assets/images/logos/wellpath.png";
import './Clients.scss';

function Clients({ title }) {

    return ( 
        <>
            <section className="Clients pb-12 md:pb-24">
                <div className="Clients__container w-1/2 mx-auto"></div>
                <div className="Clients__container max-w-5xl mx-auto">
                    <div className="Clients__row flex flex-wrap py-16 md:py-24">
                        <div className="Clients__col w-full text-center px-12 lg:px-0 mb-12 md:mb-20">
                            <h3 className="Clients__title text-xl uppercase font-bold text-gray-500 tracking-widest max-w-xl mx-auto" data-aos="fade-up" data-aos-duration="600">
                                { title }
                            </h3>
                        </div>
                        <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 px-8 lg:px-0">
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-110 lg:bg-120" style={{ backgroundImage: `url(${ hanes })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-90 lg:bg-130" style={{ backgroundImage: `url(${ vista })`, backgroundSize: '130px' }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-120 lg:bg-130" style={{ backgroundImage: `url(${ expedia })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-130 lg:bg-140" style={{ backgroundImage: `url(${ aramark })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-100 lg:bg-120" style={{ backgroundImage: `url(${ spirit })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-90 lg:bg-110" style={{ backgroundImage: `url(${ crst })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-90 lg:bg-110" style={{ backgroundImage: `url(${ draftkings })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-150 lg:bg-180" style={{ backgroundImage: `url(${ gallo })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-100 lg:bg-120" style={{ backgroundImage: `url(${ tacobell })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-120 lg:bg-130" style={{ backgroundImage: `url(${ lennar })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-110 lg:bg-130" style={{ backgroundImage: `url(${ paychex })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-130 lg:bg-150" style={{ backgroundImage: `url(${ encompass })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-50 lg:bg-70" style={{ backgroundImage: `url(${ panda })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-130" style={{ backgroundImage: `url(${ wellpath })` }} ></div>
                            {/* <div className="bg-no-repeat bg-contain bg-center h-20" style={{ backgroundImage: `url(${ mercy })` }} ></div> */}
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-150 lg:bg-180" style={{ backgroundImage: `url(${ uopx })` }} ></div>
                            <div className="bg-no-repeat bg-contain bg-center h-20 bg-90 lg:bg-110" style={{ backgroundImage: `url(${ jennieO })` }} ></div>
                        </div>
                    </div>
                </div>  
                <div className="Clients__container w-1/2 mx-auto"></div>    
            </section>
        </>
    );
}

export default Clients;