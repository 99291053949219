import { IoCodeSlashOutline } from "react-icons/io5";
import $ from "jquery";
import "jquery.easing";
import './Hero.scss';

function Hero({ title, role }) {

	$(document).on('click', 'a[data-scroll]', function(event) {
		var $anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: $($anchor.attr('href')).offset().top
		}, 1500, 'easeInOutExpo');
		event.preventDefault();
	});

	return (
		<>
			<section className="Hero flex">
				<div className="Hero__container container m-auto mt-40">
					<div className="Hero__row">
						<div className="Hero__col w-full text-center">
							<IoCodeSlashOutline className="Hero__icon bg-blue-500 text-white rounded-full p-6 mx-auto mb-10" />
							<h1 className="Hero__title text-5xl uppercase tracking-wide font-semibold mb-3 text-white" data-aos="fade-up" data-aos-duration="600" data-aos-delay="300">{ title }</h1>
							<h2 className="Hero__sub text-2xl uppercase tracking-widest font-light font-body text-white mb-14">{ role }</h2>
							<a href="#projects" className="bg-white text-gray-700 rounded py-4 px-10 uppercase tracking-widest font-semibold hover:bg-blue-500 hover:text-white" data-scroll>View My Work</a>
						</div>
					</div>
				</div>
			</section>
		</>
	); 
}

export default Hero;