import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './App.scss';

function App() {

	// Documentation: https://michalsnik.github.io/aos/
	AOS.init();

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
